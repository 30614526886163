<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_moveLocationSearch" />
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                @change="movSuppliersSelected"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.supplierRequired]"
                :error-messages="alertMessageInUnit"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品番・品名 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="ProdNameSelected"
                :items="prodNameList"
                @change="(event) => changeProductNm(event)"
                :hint="setProducts()"
                :search-input.sync="search"
                :label="$t('label.lbl_productCnCd')"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!--ロット-->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotNo"
                maxlength="20"
                :label="$t('label.lbl_lot')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- S/N -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="serial"
                maxlength="32"
                :label="$t('label.lbl_serial')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 倉庫-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="warehouseSelected"
                :items="warehouseList"
                :label="$t('label.lbl_warehouse')"
                class="txt-single"
                persistent-hint
                :hint="setWarehouse()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="item-spacer">&nbsp;</span>

            <!-- ロケ-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="locationSelected"
                :items="locationList"
                :label="$t('label.lbl_location')"
                class="txt-single"
                persistent-hint
                @change="(event) => changeLocation(event)"
                :search-input.sync="locaSearch"
                :hint="setLocation()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="item-spacer">&nbsp;</span>

            <!--品質区分-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="prodStatus"
                :items="prodStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                :hint="setStatus()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="item-spacer">&nbsp;</span>
            <!-- 期限日 -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="dueDate"
                maxlength="8"
                :label="$t('label.lbl_dueDate')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー１ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotSubkey1"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey1')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー２ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="lotSubkey2"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey2')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="searchBtn">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <!--登録ボタン領域-->
              <div class="btn-area" v-if="btnToggle == '2'">
                <!-- 指示登録処理ボタン -->
                <v-btn
                  color="primary"
                  id="btn-search"
                  class="api-btn"
                  @click="addInstrunctions()"
                  >{{ $t("btn.btn_instAddProcess") }}</v-btn
                >
                <!--実績登録処理ボタン-->
                <v-btn
                  v-if="registRole == 'true'"
                  color="primary"
                  id="btn-search"
                  class="api-btn"
                  @click="achieveAdd()"
                  >{{ $t("btn.btn_achieveAddProcess") }}</v-btn
                >
              </div>
            </div>
          </v-row>

          <!--検索結果表示-->
          <v-row>
            <div class="display-search-result-row">
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- ヘッダーロット -->
          <template v-slot:[`header.lotNo`]="{ header }">
            <span>
              <div>{{ header.text }}</div>
              <span>
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </span>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
            />
          </template>
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- 品番・品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productName.length > 20 || item.productCnCd.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 400px" v-bind="attrs" v-on="on">
                    <div>{{ item.productCnCd }}</div>
                    <div>{{ item.incidental }}</div>
                    <div>{{ item.productName }}</div>
                  </div>
                </template>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.incidental }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.productCnCd }}</div>
              <div>{{ item.incidental }}</div>
              <div>{{ item.productName }}</div>
            </div>
          </template>
          <!-- ロット/S/N -->
          <template v-slot:[`item.lotNo`]="{ item }">
            <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="width: 450px" v-bind="attrs" v-on="on">
                    {{ item.lotNo }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.lotNo }}<br />
              {{ item.dueDate }}
            </div>
          </template>
        </v-data-table>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="dialogLocation" :max-width="800"> </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.SCREEN_ID.P_MOV_001,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // ボタン表示切替
    btnToggle: "1",
    u_10: false,
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 総件数
    totalCount: 0,
    // ロケーションダイアログ
    dialogLocation: false,
    search: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // 有償/無償
    isPaid: "1",
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    // 倉庫
    warehouseSelected: "",
    warehouseList: [],
    // ロケ
    locationSelected: "",
    locationList: [],
    // 品質区分
    prodStatus: "",
    prodStatusList: [],
    // 品番・品名
    ProdNameSelected: "",
    prodNameList: [],
    lotNo: "",
    //S/N
    serial: "",
    //期限日
    dueDate: "",
    //付属キー１
    lotSubkey1: "",
    //付属キー２
    lotSubkey2: "",
    // 登録画面に渡す選択した品番リスト
    checkedList: [],
    // メニュー
    openMenu: null,
    // エラーダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    alertMessageInUnit: "",
    inputList: [],
    // ソート
    sortItem: "",
    sortOptions: {},
    locaSearch: "",
    // 実績登録権限
    registRole: "true",
    // 遷移フラグ
    transitionFlg: false,
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        text: "選択",
        value: "check",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "25%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "25%",
        align: "left",
        sortable: true,
      },
      // 倉庫/ロケ
      {
        text: i18n.tc("label.lbl_warehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "locations",
        width: "25%",
        align: "left",
        sortable: true,
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "14%",
        align: "left",
        sortable: true,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "stockQty",
        width: "8%",
        align: "right",
        sortable: true,
      },
    ],
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
    },
    //検索条件保持
    searchProdNameSelected: "",
    searchProdNameList: [],
    searchLocationSelected: "",
    searchLocationList: [],
    searchLotNo: "",
    searchSerial: "",
    searchDueDate: "",
    searchLotSubkey1: "",
    searchLotSubkey2: "",
    searchWarehouseSelected: "",
    searchProdStatus: "",
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.searchBtn();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.searchBtn();
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";

      //登録画面で戻るボタンを押下した場合
      if (this.$route.params.suppliersSelected && !this.clearFlg) {
        this.suppliersSelected = this.$route.params.suppliersSelected;
        this.ProdNameSelected = this.$route.params.itemCd ? this.$route.params.itemCd : "";
        this.prodNameList = this.$route.params.itemCdList;
        this.lotNo = this.$route.params.lotNo;
        this.serial = this.$route.params.serial;
        this.dueDate = this.$route.params.dueDate;
        this.lotSubkey1 = this.$route.params.lotSubkey1;
        this.lotSubkey2 = this.$route.params.lotSubkey2;
        this.warehouseSelected = this.$route.params.warehouse;
        this.locationSelected = this.$route.params.location;
        this.locationList = this.$route.params.locationList;
        this.prodStatus = this.$route.params.status;
        this.transitionFlg = true;
        this.checkFlg = true;
        if (this.$route.params.itemsPerPage && this.$route.params.itemsPerPageBtn) {
          this.itemsPerPage = this.$route.params.itemsPerPage;
          this.itemsPerPageBtn = this.$route.params.itemsPerPageBtn;
        }
        this.searchBtn();
      } else {
        this.suppliersSelected = "";
        this.ProdNameSelected = "";
        this.lotNo = "";
        this.warehouseSelected = "";
        this.locationSelected = "";
        this.inputList = [];
      }

      this.getInitData();
      // 実績登録権限を取得
      this.registRole = sessionStorage.getItem("P-MOV-004");
    },
    // 初期データ取得
    getInitData() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );
      const warehouses = getParameter.getWarehouseBiz();
      Promise.all([qualityList, clientList, warehouses])
        .then((result) => {
          // 画面の初期値を設定します。
          this.prodStatusList = result[0];
          this.supplierList = result[1];
          this.warehouseList = result[2];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    // 全選択チェック
    selectAllCheck() {
      // チェックボックス選択時
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },
    // 取引先セット
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // 取引先セット
    getClientName() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.checkedList[0].clientSid) {
          return this.supplierList[i].text;
        }
      }
    },
    // 品番セット
    setProducts() {
      for (var i = 0; i < this.prodNameList.length; i++) {
        if (this.prodNameList[i].value == this.ProdNameSelected) {
          return this.prodNameList[i].name;
        }
      }
    },
    // 倉庫セット
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },
    // ロケーションセット
    setLocation() {
      for (var i = 0; i < this.locationList.length; i++) {
        if (this.locationList[i].value == this.locationSelected) {
          return this.locationList[i].name;
        }
      }
    },
    // 品質区分セット
    setStatus() {
      for (var i = 0; i < this.prodStatusList.length; i++) {
        if (this.prodStatusList[i].value == this.prodStatus) {
          return this.prodStatusList[i].name;
        }
      }
    },
    movSuppliersSelected() {
      if (
        this.suppliersSelected == "" ||
        this.suppliersSelected == undefined ||
        this.suppliersSelected == null
      ) {
        this.alertMessageInUnit = i18n.tc("check.chk_inputSupplier");
        return;
      } else {
        this.alertMessageInUnit = "";
      }
    },
    //検索ボタン押下
    searchBtn() {
      // ローディング画面表示ON
      if (
        this.suppliersSelected == "" ||
        this.suppliersSelected == undefined ||
        this.suppliersSelected == null
      ) {
        this.alertMessageInUnit = i18n.tc("check.chk_inputSupplier");
        return;
      } else {
        this.alertMessageInUnit = "";
      }
      this.loadingCounter = 1;

      // 入力チェック結果フラグ
      let inputCheckResult = false;

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するため、このようなコードとなっている。
       */
      if (this.$route.params.suppliersSelected) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      // 入力チェックを実施
      if (inputCheckResult) {
        const config = this.$httpClient.createGetApiRequestConfig();
        config.params.clientSid = this.suppliersSelected;
        // ページャー処理
        if (this.page >= 2 && this.inputList.length == 0) {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }

        // 品番/品名選択時
        if (this.ProdNameSelected) {
          config.params.itemStandardSid = this.ProdNameSelected;
        }

        // ロット入力時
        if (this.lotNo) {
          config.params.lotNo = this.lotNo;
        }

        // S/N入力時
        if (this.serial) {
          config.params.serial = this.serial;
        }

        if (this.dueDate) {
          config.params.dueDate = this.dueDate; // 期限日
        }
        if (this.lotSubkey1) {
          config.params.lotSubkey1 = this.lotSubkey1; // 付属キー１
        }
        if (this.lotSubkey2) {
          config.params.lotSubkey2 = this.lotSubkey2; // 付属キー２
        }

        // 倉庫選択時
        if (this.warehouseSelected) {
          config.params.warehouseSid = this.warehouseSelected;
        }

        // ロケ選択時
        if (this.locationSelected) {
          config.params.locationSid = this.locationSelected;
        }

        // 品質区分選択時
        if (this.prodStatus) {
          config.params.qualityDiv = this.prodStatus;
        }

        // 検索値保持
        this.searchProdNameSelected = this.ProdNameSelected;
        this.searchProdNameList =
          this.ProdNameSelected == "" || this.ProdNameSelected == null
            ? []
            : new Array(...this.prodNameList);
        this.searchLocationSelected = this.locationSelected;
        this.searchLocationList =
          this.locationSelected == "" || this.locationSelected == null
            ? []
            : new Array(...this.locationList);
        this.searchLotNo = this.lotNo;
        this.searchSerial = this.serial;
        this.searchDueDate = this.dueDate;
        this.searchLotSubkey1 = this.lotSubkey1;
        this.searchLotSubkey2 = this.lotSubkey2;
        this.searchWarehouseSelected = this.warehouseSelected;
        this.searchProdStatus = this.prodStatus;

        // console.debug("searchBtn() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_LOCATION_TARGET, config)
            .then((response) => {
              // console.debug("searchBtn() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                // ページング
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;

                const list = [];
                jsonData.resIdv.locationMoveTarget.forEach((row) => {
                  let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                  list.push({
                    productCnCd: row.itemCd.substring(0, 50).trimRight(), //品番
                    productCnCdShow: row.itemCd, //品番
                    incidental: row.itemCd.slice(-50).trimRight(), //品名
                    productName: row.itemName == null ? "" : row.itemName, //品名
                    lotNo: lotNoObj.lot, //ロットNo
                    lotNoShow: row.lotNo, //ロットNo
                    lot: lotNoObj.lot, //ロットNo + S/N
                    dueDate: lotNoObj.dueDate, // 期限日 + 付属キー１ + 付属キー２
                    locations: `${row.warehouseName}/${row.locationNo}`, //倉庫ロケ
                    statusDiv: row.qualityDiv, // ステータス区分
                    status: row.qualityDivName, // 良品・不良品
                    stockQty: commonUtil.formatToCurrency(Number(row.stocks)), // 在庫数
                    locationNo: row.locationNo, //ロケーションNo
                    warehouseName: row.warehouseName, //倉庫名
                    warehouseSid: row.warehouseSid, //倉庫SID
                    itemSid: row.itemStandardSid, // 商品Sid
                    locationSid: row.locationSid, //ロケーションSID
                    clientSid: this.suppliersSelected, // 取引先SID
                  });
                });
                this.inputList = list;

                // 0件以上あればボタンを表示
                if (this.inputList.length != 0) {
                  this.btnToggle = "2";
                }

                resolve(response);

                // エラー時
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    // 指示登録ボタン押下
    addInstrunctions() {
      if (this.$refs.form.validate()) {
        // チェックした行
        const checkList = [];
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            checkList.push(this.inputList[i]);
          }
        }
        // １つもチェックされていない場合、画面遷移できない
        if (checkList.length == 0) {
          return;
        } else {
          // 指示Noの設定
          for (let i = 0; i < checkList.length; i++) {
            checkList[i].instNo = i + 1;
            this.checkedList.push(checkList[i]);
          }

          // 画面遷移
          this.$router.push({
            name: appConfig.SCREEN_ID.P_MOV_002,
            params: {
              checkList: this.checkedList,
              // 検索条件を渡す
              supplier: this.checkedList[0].clientSid,
              itemCd: this.searchProdNameSelected,
              itemCdList: this.searchProdNameList,
              lotNo: this.searchLotNo,
              serial: this.searchSerial,
              dueDate: this.searchDueDate,
              lotSubkey1: this.searchLotSubkey1,
              lotSubkey2: this.searchLotSubkey2,
              clientName: this.getClientName(),
              warehouse: this.searchWarehouseSelected,
              location: this.searchLocationSelected,
              locationList: this.searchLocationList,
              status: this.searchProdStatus,
              name: "MovLocationInstSearch",
              itemsPerPage: this.itemsPerPage,
              itemsPerPageBtn: this.itemsPerPageBtn,
            },
          });
        }
      }
    },

    // 実績登録押下時
    achieveAdd() {
      if (this.$refs.form.validate()) {
        // チェックした行
        const checkList = [];
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            checkList.push(this.inputList[i]);
          }
        }

        // １つもチェックされていない場合、画面遷移できない
        if (checkList.length == 0) {
          return;
        } else {
          // 指示Noの設定
          for (let i = 0; i < checkList.length; i++) {
            checkList[i].instNo = i + 1;
            this.checkedList.push(checkList[i]);
          }

          // 画面遷移
          this.$router.push({
            name: appConfig.SCREEN_ID.P_MOV_004,
            params: {
              checkList: this.checkedList,
              // 検索条件を渡す
              supplier: this.checkedList[0].clientSid,
              clientName: this.getClientName(),
              itemCd: this.searchProdNameSelected,
              itemCdList: this.searchProdNameList,
              lotNo: this.searchLotNo,
              serial: this.searchSerial,
              dueDate: this.searchDueDate,
              lotSubkey1: this.searchLotSubkey1,
              lotSubkey2: this.searchLotSubkey2,
              warehouse: this.searchWarehouseSelected,
              location: this.searchLocationSelected,
              locationList: this.searchLocationList,
              status: this.searchProdStatus,
              name: "MovLocationAchieveAdd",
            },
          });
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.prodNameList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.prodNameList = this.prodNameList.filter((v) => v.value == value);
    },

    /**
     * プルダウンチェンジイベント(ロケ)
     */
    changeLocation(value) {
      // 既存品番/品名を選択時
      let val = this.locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.locationHint = val.text;
        this.locaSearch = val.text;
      }
      this.locationList = this.locationList.filter((v) => v.value == value);
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.checkFlg) {
        // 遷移フラグ
        this.checkFlg = false;
        // クリアフラグ
        this.clearFlg = true;
      }
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    moveLocDateCal(val) {
      this.moveLocDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.searchBtn(false);
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MOV_001_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.searchBtn(false);
        }
      },
      deep: true,
    },
    search(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 遷移後
        if (this.transitionFlg) {
          this.transitionFlg = false;
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.prodNameList = list.concat(result[0]);
              this.prodNameList.unshift({ name: "", text: "", value: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.prodNameList.length = 0;
        }
      }
    },
    locaSearch(val) {
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.locationHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // ローディング画面表示ON
          this.loadingCounter = 1;

          const config = this.$httpClient.createGetApiRequestConfig();
          config.params.locationNo = val;
          config.params.officeSid = sessionStorage.getItem("sales_office_sid");
          // "0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得
          config.params.searchCategoryDiv = "0";
          // 処理区分
          config.params.processDiv = "01";
          // 品質区分
          if (this.prodStatus) {
            config.params.qualityDiv = this.prodStatus;
          }
          // 倉庫が選択されていれば倉庫に紐づいたロケーションを取得
          if (this.warehouseSelected) {
            config.params.warehouseSid = this.warehouseSelected;
          }
          // console.debug("locaSearch(val) Config", config);
          return new Promise((resolve, reject) => {
            this.$httpClient
              .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
              .then((response) => {
                // console.debug("locaSearch(val) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                if (jsonData.resCom.resComCode == "000") {
                  const list = [];
                  jsonData.resIdv.locations.forEach((row) => {
                    list.push({
                      text: row.locationNo,
                      value: row.locationSid,
                      name: row.locationNo,
                    });
                  });
                  this.locationList = list;
                  resolve(response);
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        } else {
          this.locationList.length = 0;
        }
      }
    },
    suppliersSelected() {
      if (this.transitionFlg) {
        // 遷移後
        return;
      } else {
        //品番/品名クリア
        this.prodNameList = [];
        this.ProdNameSelected = "";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}

// #listData {
//   &.v-data-table--fixed-header ::v-deep {
//     //テーブルデータ部分
//     td {
//       &:nth-child(7) {
//         color: red;
//         font-weight: 900;
//       }
//     }
//   }
// }
</style>
